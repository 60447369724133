.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: transparent;
}

.navbar-left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.navbar-left a,
.navbar-left a:visited,
.navbar-left a:hover,
.navbar-left a:active {
  text-decoration: none;
}

.navbar-left img {
  height: 40px;
  width: 40px;
}

.navbar-left span {
  color: white;
  font-weight: 900;
  letter-spacing: 3px;
  font-size: 25px;
}

.navbar-center {
  display: flex;
  justify-content: center;
}

.navbar-center ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-center li {
  margin-right: 1.5rem;
}

.navbar-center a, a:visited, a:active {
  color: rgb(226, 226, 226);
  text-decoration: none;
}

.navbar-center a:hover {
  color: rgb(255, 255, 255);
}

.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-right span {
  margin-right: 1rem;
  color: white;
}

.navbar-right a span{
  font-weight: regular;
  color: rgb(254, 97, 220);
}

.navbar-right a {
  text-decoration: none;
}

/* Style for screens smaller than 768px */
@media screen and (max-width: 767px) {
  .navbar {
    justify-content: space-between;
  }

  .navbar-right span {
    display: none;
  }

  .navbar-right a span {
    display: inline;
  }

  .navbar-left span {
    display: none;
  }

  .navbar-left img {
    height: 40px;
    width: 40px;
  }
}
