.hero-section {
  background-color: transparent;
  color: #fff;
  display: flex;
  height: calc(100vh - 60px);
  padding: 0 1rem;
  justify-content: space-between;
  align-items: center;
  animation-name: fade-in;
  animation-duration: 2s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.hero-section .left {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero-section .right{
  justify-content: center;
  align-items: center;
}

.hero-section h1 {
  font-size: 3rem;
  margin: 0;
  margin-bottom: 1rem;
}

.hero-section h1 .gradient-text {
  background: -webkit-linear-gradient(135deg, #d94cde, #db358e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-section h2 {
  font-size: 2rem;
  margin: 0;
  margin-bottom: 2rem;
}

.hero-section p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
/* main seciton */

main {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

main h2 {
  font-size: 2rem;
  margin: 0;
  margin-bottom: 1rem;
}

main p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

/* buttons */
.btn-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
}

.btn {
  width: 30%;
  transition: transform 0.2s ease-in-out;
  box-shadow: 10 2px 8px rgba(0, 0, 0, 0.5);

}

.btn:hover {
  transform: scale(1.1);
}


/* media queries */

.hero-section .right .mockup-1{
  --scale: 3;
  margin-top: 15rem;
  transform: scale(var(--scale));
  height: 200px;
  margin-left: 15rem;
}

.hero-section .right .mockup-2 {
  --scale: 3;
  height: 200px;
  transform: scale(var(--scale));
  z-index: -1;
  position: relative;
  margin-left: 22rem;
  padding-bottom: 5rem;
}

@media screen and (max-width: 1200px) {
  .hero-section .right .mockup-1{
    --scale: 2.8;
    margin-left: 5rem;
  }

  .hero-section .right .mockup-2 {
    --scale: 2.8;
    margin-left: 10rem;
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .hero-section {
    flex-direction: column-reverse;
    margin-top: 3rem;
  }

  .hero-section .left h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .hero-section .left h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .hero-section p {
    font-size: 1rem;
    margin-bottom: 3rem;
  }

  .btn-container {
    flex-direction: row;
    gap: 1rem;
  }

  .btn {
    width: 50%;
  }

  .hero-section .right .mockup-1 {
    --scale: 2;
    margin-top: 3rem;
    margin-left: 2rem;
    padding-top: 8rem;
  }

  .hero-section .right .mockup-2 {
    --scale: 2;
    margin-left: 2rem;
    padding-left: 2rem;
    margin-bottom: 1rem;
    padding-bottom: 3rem;

  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .hero-section .right .mockup-1{
    --scale: 2;
    margin-left: 5rem;
  }

  .hero-section .right .mockup-2 {
    --scale: 2;
    margin-left: 10rem;
    padding-bottom: 2rem;
  }

  .btn-container {
    flex-direction: row;
    gap: 1rem;
  }

  .btn {
    width: 50%;
  }
}
